import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
// import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { DateTime } from 'luxon';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';

// const Bold = ({ children }) => <span className="bold">{children}</span>;
// const Text = ({ children }) => <p className="align-center">{children}</p>;

const options = {
  // renderMark: {
  //   [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  // },
  // renderNode: {
  //   [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  // },
};

export default function SocialCardTemplate({
  data: {
    site: {
      siteMetadata: { siteUrl },
    },
    card: {
      title,
      description,

      image,
      url,
    },
  }, // this prop will be injected by the GraphQL query below.
}) {
  useEffect(() => {
    console.log(window.location.origin);
    console.log(siteUrl);
    if (['https://vannahoward.com', 'http://localhost:8000', 'http://localhost:9000', 'http://localhost.9001'].includes(url)) {
      // console.log(1)
      navigate(url || '/');
    } else {
      // console.log(2);
      window.location.replace(url || '/');
    }
  });
  return (
    <Layout container={true}>
      <SEO title={title} description={description} image={image && image.file.url} />
      <section className="section">
        <p className="is-size-1 is-uppercase has-text-center">Redirecting...</p>
      </section>
    </Layout>
  );
}
export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    card: contentfulSocialCard(slug: { eq: $slug }) {
      title
      description
      url
      image {
        file {
          url
        }
      }
    }
  }
`;
